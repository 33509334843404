import capitalize from 'utils/capitalize';
import individualWalletTransactions from './individualWalletTransactions';

const request = category => {
  if (category !== 'transactions') {
    return [
      'social_fund_request',
      'social_fund_approved',
      'social_fund_rejected',
      'loan_request',
      'loan_approved',
      'loan_rejected',
      'loan_write_off_request',
      'loan_write_off_approved',
      'loan_write_off_rejected',
      'share_out_request',
      'share_out_approved',
      'share_out_rejected',
      'bulk_withdrawal_request',
      'bulk_withdrawal_approved',
      'bulk_withdrawal_rejected',
      'bulk_share_out',
    ].map(key => ({
      key,
      value: key,
      text: capitalize(key.replace(/_/g, ' ')),
    }));
  }

  return [];
};

const transactions = category => {
  if (category === 'transactions') {
    return [
      'social_fund',
      'loan',
      'saving',
      'share_out',
      'bulk_withdrawal',
      'bulk_deposit',
      'bulk_share_out',
      'fine',
      'remove_member',
      'agent_commission',
      'overdue_loans_interests',
      'group_micro_loan',
    ].map(key => ({
      key,
      value: key,
      text: `${
        key === 'loan'
          ? 'Loan repayment'
          : key === 'fine'
          ? 'Fine payment'
          : `${capitalize(key.replace(/_/g, ' '))}`
      }`,
    }));
  }

  return [];
};

export default category => [
  ...transactions(category),
  ...request(category),
];

const individualWalletTypes = [
  'individual_wallet',
  ...individualWalletTransactions,
].map(type => ({
  key: type,
  value: type,
  text: capitalize(type.replace(/_/g, ' ')),
}));

export const IndividualUserTransactions = () => [
  ...transactions('transactions'),
  ...individualWalletTypes,
];

export const agentActivitiesTypes = [
  'create_group',
  'add_member',
  'bulk_sms',
  'creat_meeting',
  'reset_pin',
  'change_phone_number',
  'update_saving_group',
  'update_member_info',
  'change_member_committee',
].map(type => ({
  key: type,
  value: type,
  text: capitalize(type.replace(/_/g, ' ')),
}));
